import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { customRequest } from "../services/httpRequest/axios";
import moment from "moment";

const init = {
   offerStorage: null,
   optionTarifaireStorage: null,
   optionStorage: [],
   forceFacturationReele: false,
   saleTCOStep: ["SOUSCRIPTION"],
}

const sendQuoteToClient = createAsyncThunk(
   'sale/sendQuoteToClient',
   async (payload, thunkAPI) => {
      const res = await customRequest.post(`/send-quote`, {
         recipient: thunkAPI.getState().sale?.customerEmail,
         genderFistnameLastname: thunkAPI.getState().sale?.gender + " " + thunkAPI.getState().sale?.givenName + " " + thunkAPI.getState().sale?.familyName,
         emailCustomer: thunkAPI.getState().sale?.customerEmail,
         phoneCustomer: thunkAPI.getState().sale?.customerMobile,
         pdlStreet: thunkAPI.getState().sale?.PDLInfos?.deliveryAddress?.housenumberAndStreet,
         pdlZipcode: thunkAPI.getState().sale?.PDLInfos?.deliveryAddress?.zipCode,
         pdlCity: thunkAPI.getState().sale?.PDLInfos?.deliveryAddress?.city,
         billingStreet: thunkAPI.getState().sale?.billingAddress?.housenumberAndStreet,
         billingZipcode: thunkAPI.getState().sale?.billingAddress?.zipCode ?? thunkAPI.getState().sale?.billingAddress?.inseeCode,
         billingCity: thunkAPI.getState().sale?.billingAddress?.city,
         offerName: thunkAPI.getState().sale?.offerStorage?.name,
         billingType: thunkAPI.getState().sale?.consoMode,
         dateBeginingSubscription: moment(thunkAPI.getState().sale?.debitingDate.replace(/['"]+/g, '')).format("YYYY-MM-DDTHH:mm:ssZ"),
         bankAccountHolderName: thunkAPI.getState().sale?.givenHolderBank,
         iban: thunkAPI.getState().sale?.payment.iban,
         pdl: thunkAPI.getState().sale?.PDLnumber,
         powerPdl: thunkAPI.getState().sale?.PDLInfos?.contractPowerMax.valeur,
         pricingOption: thunkAPI.getState().sale?.currentOffer?.selectTarif,
         estimatedCar: (Number(thunkAPI.getState().sale?.PDLInfos.estimatedCar.carBase.value)),
         subscriptionPrice: thunkAPI.getState().sale?.monthlyAmountDetailed?.aboPartFixeMensuelTTC,
         pricePerKwhBase: thunkAPI.getState().sale?.monthlyAmountDetailed?.consoPartVariableBaseTTC,
         pricePerKwhHp: thunkAPI.getState().sale?.monthlyAmountDetailed?.consoPartVariableHpTTC,
         pricePerKwhHc: thunkAPI.getState().sale?.monthlyAmountDetailed?.consoPartVariableHcTTC,
         monthlyPayment: thunkAPI.getState().sale?.valueMonthSelected,
         yearlyPayment: thunkAPI.getState().sale?.valueMonthSelected * 12,
      });
      return res;
   }
)

const saleSlice = createSlice({
   name: "sale",
   initialState: {
      offerStorage: null,
      optionTarifaireStorage: null,
      optionStorage: [],
      forceFacturationReele: false,
      saleTCOStep: ["SOUSCRIPTION"],
   },
   reducers: {
      update: (state, action) => {
         state[action.payload.target] = action.payload.value;
      },
      updateOption: (state, action) => {
         if (action.payload.value.isPicked === true) {
            state.optionStorage = _.uniqBy(
               [...state.optionStorage, action.payload.value],
               "ouid",
               "name"
            );
         } else {
            state.optionStorage = _.uniq(
               state.optionStorage.filter(
                  (o) => o?.ouid !== action.payload.value.ouid
               )
            );
         }
      },
      emptyOption: (state) => {
         state.optionStorage = [];
      },
      empty: (state) => (state = init),
      extraReducers: (builder) => {
         builder.addCase(sendQuoteToClient.fulfilled, (state, action) => {
            //to implement
         })
      },
   },
});

export const { update, updateOption, emptyOption, empty } = saleSlice.actions;

export { sendQuoteToClient };

export default saleSlice.reducer;
