/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Modal, TextField } from "@mui/material";
import Button from "../../../components/Button/Button";
import ComboBoxObject from "../../../components/ComboBox/ComboBoxObject";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getExportVentes,
  getListSociete,
  getListVente,
  getOffres,
  getUsers,
  getVente,
  putConfirmerVente,
  putVente,
} from "../../../core/services/httpRequest/axios";

import { ReactComponent as Active } from "../../../core/assets/picto/active.svg";
import { ReactComponent as Inactive } from "../../../core/assets/picto/inactive.svg";
import { ReactComponent as Dwnld } from "../../../core/assets/picto/dwnld.svg";

import ArrowDownSvg from "../../../components/SvgComponents/ArrowDownSvg";
import ArrowUpSvg from "../../../components/SvgComponents/ArrowUpSvg";
import FilterSvg from "../../../components/SvgComponents/FilterSvg";
import _ from "lodash";
import "./styles/ventes.scss";
import VentesTable from "../../../components/BOCO/TableComponents/VentesTable/VentesTable";
import TextInput from "../../../components/TextInput/TextInput";
import GeoExclu from "../../../components/BOCO/GeoExclu/GeoExclu";
import DatePicker from "../../../components/DatePicker/datePickerV1";
import PaginationManager from "../../../components/BOCO/TableComponents/Pagination/PaginationManager";
import PopindDetailVente from "../../../components/BOCO/PopinDetailVente/PopinDetailVente.js";
import Notifaction from "../../../components/BOCO/Notification/Notifaction";
import { puissanceObject } from "../../../utils/BOCO/enums";
import PopinAnnuleVente from "../../../components/BOCO/PopinAnnuleVente/PopinAnnuleVente";
import { internationalMobilePhoneToRegular, isAnnule } from "../../../utils";
import * as paramsStore from "../../../core/stores/paramsStore";
import { sendNotif } from "../../../core/stores/utils";
import PopinValidVente from "../../../components/BOCO/PopinValidVente/PopinValidVente";
import CSVVentes from "../../../components/BOCO/CSVContainers/CSVVentes";
import moment from "moment";
import Search from "../../../components/SvgComponents/SearchSVG";
import PermissionWrapper from "../../../components/HOC/PermissionWrapper";
import handleRequestResponse from "../../../utils/responsesHandler";

const Ventes = () => {
  const [authorization, setAuthorization] = useState("");

  const [listSelectedVente, setListSelectedVente] = useState([]);
  const [openDetailVente, setOpenDetailVente] = useState(false);
  const [openAnnuleVente, setOpenAnnuleVente] = useState(false);
  const [isVenteAConfirmerParCourtier, setIsVenteAConfirmerParCourtier] = useState(false);
  const [selectedListContainsVenteAConfirmerParCourtier, setSelectedListContainsVenteAConfirmerParCourtier] = useState(false); 
  const [openValidVente, setOpenValidVente] = useState(false);
  const [toggleFilters, setToggleFilters] = useState(false);
  const [selectedVente, setSelectedVente] = useState({});

  // values for table and pagination
  const [allRows, setAllRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(0);
  const [rowsPerPageInit, setRowsPerPageInit] = useState(10);
  const [totalList, setTotalList] = useState();
  const [totalPages, setTotalPages] = useState();

  const [filters, setFilters] = useState({
    commissionVenteMin: "",
    commissionVenteMax: "",
    departements: "",
    startDate: null,
    endDate: null,
    societeCourtage: "",
    responsableVendeur: "",
    vendeur: "",
    statut: "",
    nomOffre: "",
    puissanceSouscrite: "",
    societe: "",
    responsable: "",
    recherche: "",
    elementTarget: "dateCreationVente",
    tri: "desc",
  });

  const [vendeurs, setVendeurs] = useState([]);
  const [responsablesVendeurs, setResponsablesVendeurs] = useState([]);
  const [societes, setSocietes] = useState([]);
  const [offres, setOffres] = useState([]);

  const [annulationSuccess, setAnnulationSuccess] = useState(false);
  const [disabledAnnulation, setDisabledAnnulation] = useState(false);

  const [validMassDone, setValidMassDone] = useState(false);

  const listStatutVente = [
    {
      name: "A confirmer par courtier",
      value: "A_CONFIRMER",
    },
    {
      name: "A valider par Primeo",
      value: "A_VALIDER",
    },
    {
      name: "Validée",
      value: "VALIDE",
    },
    {
      name: "Annulée",
      value: "ANNULE",
    },
  ];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const authorizations = useSelector((state) => state?.params?.authorizations);
  const currentUser = useSelector((state) => state.auth.broker);

  const headCellsDefault = [
    {
      id: "nomSociete",
      idForTri: "societe",
      numeric: false,
      disablePadding: true,
      label: t("ventes.societe"),
    },
    {
      id: "idVenteTripica",
      idForTri: "idMetier",
      numeric: false,
      disablePadding: true,
      label: t("ventes.idvente"),
    },
    {
      id: "nomClient",
      idForTri: "nomClient",
      numeric: false,
      disablePadding: true,
      label: t("ventes.client"),
    },
    {
      id: "villePDL",
      idForTri: "villePDL",
      numeric: false,
      disablePadding: true,
      label: t("ventes.adresse"),
    },
    {
      id: "nomOffre",
      idForTri: "nomOffre",
      numeric: false,
      disablePadding: true,
      label: t("ventes.offre"),
    },
    {
      id: "puissancePDL",
      idForTri: "puissancePDL",
      numeric: false,
      disablePadding: true,
      label: t("ventes.puissance"),
    },
    {
      id: "commissionVente",
      idForTri: "commissionVente",
      numeric: false,
      disablePadding: true,
      label: t("ventes.com"),
    },
    {
      id: "dateCreationVente",
      idForTri: "dateCreationVente",
      numeric: false,
      disablePadding: true,
      label: t("ventes.dateVente"),
    },
    {
      id: "dateAconfirmer",
      idForTri: "dateAvalider",
      numeric: false,
      disablePadding: true,
      label: t("ventes.statut"),
    },
    {
      id: "jourRendezVous",
      idForTri: "jourRendezVous",
      numeric: false,
      disablePadding: true,
      label: t("ventes.dateRdv"),
    },
  ];

  const [headCells, setHeadCells] = useState(headCellsDefault);

  const [listResponsableV, setResponsablesV] = useState();
  const [listVendeurs, setListVendeurs] = useState();
  const [listSociete, setListSociete] = useState();

  useEffect(() => {
    getOffres().then((res) => setOffres(res.data));
  }, []);

  // init autorisation
  useEffect(() => {
    setAuthorization(currentUser.type);
  }, [currentUser]);

  // societe value and responsable value for filter
  useEffect(() => {
    const isNotVendeur = authorization !== authorizations[3] && !!authorization;

    const isAdmin = authorization === authorizations[0];
    const isNotNull = authorization === "";

    if (isAdmin) {
      getListSociete().then((res) => {
        let businesses = [];
        res.data.forEach((s) => {
          businesses.push({
            idSocieteCourtage: s.idSociete,
            name: s.nomSociete,
          });
        });
        setSocietes(businesses);
      });
    }

    if (isNotVendeur) {
      getUsers().then((res) => {
        let respos = res?.data?.filter((u) => {
          if (u?.type === "RESPONSABLE_VENDEUR" && u.statut === "ACTIF") {
            return {
              name: u.nomResponsable,
              idUtilisateur: u.idUtilisateur,
            };
          }
        });

        setResponsablesVendeurs(respos);

        let vendeurs = res?.data?.filter((v) => {
          if (v?.type === "VENDEUR" && v.statut === "ACTIF") {
            return {
              name: v.prenom + " " + v.nom,
              idUtilisateur: v.idUtilisateur,
            };
          }
        });
        setVendeurs(vendeurs);
      });
    }

    if (!isAdmin && !isNotNull) {
      setHeadCells((cell) =>
        cell.filter((headeCell) => headeCell.id !== "nomSociete")
      );

      setFilters({
        ...filters,
        societeCourtage: currentUser.idSocieteCourtage,
      });
    } else {
      setHeadCells(headCellsDefault);
    }
  }, [authorization]);

  // Conditions filter societe/responsable/vendeurs
  useEffect(() => {
    let newSocietes = societes;
    let newResponsablesV = responsablesVendeurs;
    let newVendeurs = vendeurs;

    if (filters.societeCourtage) {
      newResponsablesV = filterResponsable();
      if (filters.responsableVendeur) {
        newSocietes = filterSociete();
        newVendeurs = filterVendeurs();
      } else {
        newVendeurs = filterVendeursWithoutResponsable();
      }
    }
    if (filters.responsableVendeur) {
      if (filters.societeCourtage) {
        newResponsablesV = filterResponsable();
      } else {
        newVendeurs = filterVendeurs();
      }
    }
    if (filters.vendeur) {
      if (filters.responsableVendeur) {
        newVendeurs = filterVendeurs();
        if (newVendeurs.length === 0) {
          setFilters({
            ...filters,
            vendeur: "",
          });
        }
      } else {
        newVendeurs = filterVendeursWithoutResponsable();
      }
    }

    setListSociete(
      newSocietes.map((b) => ({
        value: b.idSocieteCourtage,
        name: b.name,
      }))
    );
    setResponsablesV(
      newResponsablesV.map((r) => ({
        value: r.idUtilisateur,
        name: r.prenom + " " + r.nom,
      }))
    );
    setListVendeurs(
      newVendeurs.map((r) => ({
        value: r.idUtilisateur,
        name: r.prenom + " " + r.nom,
      }))
    );
  }, [filters, societes, responsablesVendeurs, vendeurs]);

  const filterSociete = () => {
    if (!!societes && societes?.length > 0) {
      return societes.filter(
        (r) => r.idSocieteCourtage === filters.societeCourtage
      );
    } else {
      return societes;
    }
  };

  const filterResponsable = () => {
    if (!!responsablesVendeurs && responsablesVendeurs?.length > 0) {
      return responsablesVendeurs.filter(
        (r) => r.idSocieteCourtage === filters.societeCourtage
      );
    } else {
      return responsablesVendeurs;
    }
  };

  const filterVendeurs = () => {
    if (!!vendeurs && vendeurs?.length > 0 && filters.responsableVendeur) {
      return vendeurs.filter(
        (v) => v.idResponsable === filters.responsableVendeur
      );
    } else {
      return vendeurs;
    }
  };

  const filterVendeursWithoutResponsable = () => {
    if (!!vendeurs && vendeurs?.length > 0 && filters.societeCourtage) {
      return vendeurs.filter(
        (v) => v.idSocieteCourtage === filters.societeCourtage
      );
    } else {
      return vendeurs;
    }
  };

  const selectWithJustVendeur = (e) => {
    const vendeurInfo = vendeurs.filter((v) => v.idUtilisateur === e);

    const isAdmin = authorization === authorizations[0];

    const condition = isAdmin
      ? filters.responsable === "" &&
      filters.societeCourtage === "" &&
      filters.vendeur === ""
      : filters.responsable === "" && filters.vendeur === "";

    if (condition) {
      setFilters({
        ...filters,
        responsableVendeur: vendeurInfo[0].idResponsable,
        societeCourtage: vendeurInfo[0].idSocieteCourtage,
        vendeur: e,
      });
    } else {
      setFilters({
        ...filters,
        vendeur: e,
      });
    }
  };

  const selectWithJustResponsable = (e) => {
    const responsableInfo = responsablesVendeurs.filter(
      (v) => v.idUtilisateur === e
    );

    if (
      filters.responsable === "" &&
      filters.societeCourtage === "" &&
      filters.vendeur === ""
    ) {
      setFilters({
        ...filters,
        responsableVendeur: e,
        societeCourtage: responsableInfo[0].idSocieteCourtage,
      });
    } else {
      setFilters({
        ...filters,
        responsableVendeur: e,
      });
    }
  };

  // Annulation ventes
  function handleAnnulationSuccess() {
    setOpenAnnuleVente(false);
    setOpenDetailVente(false);
    setAnnulationSuccess(true);
    setListSelectedVente([]);
  }

  function resetFilters() {
    setFilters({
      commissionVenteMin: "",
      commissionVenteMax: "",
      departements: "",
      startDate: null,
      endDate: null,
      societeCourtage: "",
      responsableVendeur: "",
      vendeur: "",
      statut: "",
      nomOffre: "",
      puissanceSouscrite: "",
      societe: "",
      responsable: "",
      recherche: "",
      elementTarget: "dateCreationVente",
      tri: "asc",
    });

    const ifAllRow = rowsPerPageInit === -1 ? 0 : rowsPerPageInit;

    getListVente(pageCurrent, ifAllRow, {
      commissionVenteMin: "",
      commissionVenteMax: "",
      departements: "",
      startDate: null,
      endDate: null,
      societeCourtage: "",
      responsableVendeur: "",
      vendeur: "",
      statut: "",
      nomOffre: "",
      puissanceSouscrite: "",
      societe: "",
      responsable: "",
      recherche: "",
      elementTarget: "dateCreationVente",
      tri: "asc",
    }).then((res) => {
      setRows(res?.data?.listVentes);
      setTotalList(res?.data?.totalItems);
      setTotalPages(res?.data?.totalPages);
      setPageCurrent(res?.data?.currentPage);
    });
  }

  // Confirmation ventes ICI (via popin valid mass)

  function handleValidVente(list) {
    let response = [];
    let IDS = list?.map((v) => v.idMetier).join(", ");

    Promise.all(
      list?.map((v) => {
        const rowsIndexSelected = _.findIndex(rows, function (o) {
          return o.idVente === v.idVente;
        });
        getVente(v.idVente).then((res) => {
          const data = res?.data;
          let query = {
            idVente: v.idVente,
            idMetier: v.idMetier,
            commissionVente: v.commissionVente,
            prenomClient: rows[rowsIndexSelected]?.prenomClient,
            nomClient: rows[rowsIndexSelected]?.nomClient,
            periodeRendezVous: rows[rowsIndexSelected]?.periodeRendezVous,
            emailClient: rows[rowsIndexSelected]?.emailClient,
            jourRendezVous: rows[rowsIndexSelected]?.jourRendezVous,
            telephone: internationalMobilePhoneToRegular(
              "+33",
              "0",
              data?.telephone
            ),
            adresseFacturation: {
              street1: data?.adresseFacturation?.street1,
              hamlet: data?.adresseFacturation?.street2,
              supplement1: data?.adresseFacturation?.supplement1,
              supplement2: data?.adresseFacturation?.supplement2,
              city: data?.adresseFacturation?.city,
              country: "FR",
              postCode: data?.adresseFacturation?.postCode,
            },
            modifie: true,
          };

          putVente(query).then((res) =>
            handleRequestResponse(
              res,
              () => {
                // confirmer la vente

                putConfirmerVente(v.idVente).then((res) => {
                  response.push(res?.status);
                });
              },
              () => {
                response.push(res?.status);
              }
            )
          );
        });
      })
    );

    setTimeout(() => {
      setValidMassDone(true);
      return response?.every((r) => r === 200)
        ? dispatch(paramsStore.update(sendNotif("validContratMass", IDS)))
        : dispatch(
          paramsStore.update(sendNotif(response.find((r) => r !== 200)))
        );
    }, 2000);
  }

  useEffect(() => {
    if (setValidMassDone) {
      const ifAllRow = rowsPerPageInit === -1 ? 0 : rowsPerPageInit;
      getListVente(pageCurrent, ifAllRow, filters).then((res) => {
        setRows(res?.data?.listVentes);
        setTotalList(res?.data?.totalItems);
        setTotalPages(res?.data?.totalPages);
        setPageCurrent(res?.data?.currentPage);
      });
      setListSelectedVente([]);
    }
  }, [validMassDone]);

  function disableValidAndAnnul(list) {
    // TODO: la sélection massive des ventes pour actions doit passer un filtre
    //       pour connaitres les états des ventes. Si elles ne sont pas toutes identiques alors
    //       les actions possibles dessus sont différentes (normalement). On devra griser les boutons
    //       valider et annuler comme sur la page des vendeurs (changement de responsable intersociété impossible)
    // je dois comparer les properties de date et si elles sont différentes alors
  }

  // Filter add filter
  function applyFilters() {
    const ifAllRow = rowsPerPageInit === -1 ? 0 : rowsPerPageInit;

    getListVente(pageCurrent, ifAllRow, filters).then((res) => {
      setRows(res?.data?.listVentes);
      setTotalList(res?.data?.totalItems);
      setTotalPages(res?.data?.totalPages);
      setPageCurrent(res?.data?.currentPage);
    });
  }

  function onEnterApplyFilters(event) {
    event.preventDefault();
    applyFilters();
  }

  function preventInvalidDate(date) {
    if (moment(date).format("DD-MM-YYYY") !== "Invalid date" && date !== null) {
      return true;
    }
    return false;
  }

  const toggleExport = () => {
    if (
      preventInvalidDate(filters.startDate) &&
      preventInvalidDate(filters.endDate)
    ) {
      return true;
    }
    return false;
  };

  // Update pagination and rows for
  const handleOnChange = (pageSeleted, rowPerPageSelected) => {
    if (pageSeleted !== pageCurrent) {
      setPageCurrent(pageSeleted);
    }

    if (rowPerPageSelected === -1) {
      setRowsPerPageInit(totalList);
    } else {
      setRowsPerPageInit(rowPerPageSelected);
    }
  };

  function handleOpenDetailVente(v) {
    setOpenDetailVente(true);
    setSelectedVente(v);
    changeSelectedVente(v);
  }

  const changeSelectedVente = (valueId) => {
    const rowsIndexSelected = _.findIndex(rows, function (o) {
      return o.idVente === valueId;
    });

    const newValueForAnnulation = [
      {
        idVente: rows[rowsIndexSelected].idVente,
        idMetier: rows[rowsIndexSelected].idMetier,
        commissionVente: rows[rowsIndexSelected].commissionVente,
        prenomClient: rows[rowsIndexSelected].prenomClient,
        nomClient: rows[rowsIndexSelected].nomClient,
        periodeRendezVous: rows[rowsIndexSelected].periodeRendezVous,
        emailClient: rows[rowsIndexSelected].emailClient,
        jourRendezVous: rows[rowsIndexSelected].jourRendezVous,
        adresseFacturation: {
          city: rows[rowsIndexSelected].villePDL,
          country: "FR",
          postCode: rows[rowsIndexSelected].codePostalPDL,
        },
        modifie: true,
      },
    ];
    setListSelectedVente(newValueForAnnulation);
  };

  useEffect(() => {
    setAnnulationSuccess(false);
    const ifAllRow = rowsPerPageInit === -1 ? 0 : rowsPerPageInit;

    getListVente(pageCurrent, ifAllRow, filters).then((res) => {
      setRows(res?.data?.listVentes);
      setTotalList(res?.data?.totalItems);
      setTotalPages(res?.data?.totalPages);

      if (pageCurrent !== res?.data?.currentPage) {
        setPageCurrent(res?.data?.currentPage);
      }
    });
  }, [pageCurrent, rowsPerPageInit, annulationSuccess]);

  useEffect(() => {
    if (totalList)
      getListVente(0, totalList, filters).then((res) => {
        setAllRows(res?.data?.listVentes);
      });
  }, [totalList]);

  useEffect(() => {
    if (listSelectedVente.length > 0) {
      let target = rows?.filter((v) =>
        listSelectedVente.find((a) => a.idVente === v.idVente)
      );

    const containsVenteToConfirm = listSelectedVente.some(vente => vente.statut === "A_CONFIRMER_COURTIER");
    setSelectedListContainsVenteAConfirmerParCourtier(containsVenteToConfirm);
      return setDisabledAnnulation(isAnnule(target));
    } else {
      setDisabledAnnulation(false);
      setSelectedListContainsVenteAConfirmerParCourtier(false);
    }
  }, [listSelectedVente]);

  const handleClickTri = () => {
    applyFilters();
  };

  return (
    <div className="vente_container">
      <Notifaction />
      {/* Header */}
      <div className="vente_header">
        <div className="top">
          <div>
            <div className="left">
              <h2>{t("ventes.title")}</h2>
              <Button
                className={
                  toggleFilters
                    ? "blue outline small"
                    : "blue outline small black"
                }
                onClick={() => setToggleFilters(!toggleFilters)}
              >
                <FilterSvg fill={toggleFilters ? "#0ABBF0" : "#000"} />
                <p>{t("users.filters")}</p>
                {toggleFilters ? (
                  <ArrowUpSvg fill="black" />
                ) : (
                  <ArrowDownSvg fill="black" />
                )}
              </Button>
              <p
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  margin: "0 0 0 12px",
                }}
                onClick={() => resetFilters()}
              >
                Effacer
              </p>
            </div>
          </div>
          <div className="cta">
            {/* modal  */}
            <PermissionWrapper requiredPermission={["RETREIVE_DETAIL_SALE"]}>
              <Modal
                open={openDetailVente}
                onClose={() => setOpenDetailVente(false)}
              >
                <>
                  <PopindDetailVente
                    onClose={() => setOpenDetailVente(false)}
                    onSuccess={async () => {
                      const ifAllRow =
                        rowsPerPageInit === -1 ? 0 : rowsPerPageInit;

                      let res = await getListVente(pageCurrent, ifAllRow, filters)
                      console.log(res?.data?.listVentes)
                      setRows(res?.data?.listVentes);
                      setTotalList(res?.data?.totalItems);
                      setTotalPages(res?.data?.totalPages);
                      setPageCurrent(res?.data?.currentPage);

                    }}
                    selectedVente={selectedVente}
                    user={currentUser}
                    list={rows}
                    onChange={handleOnChange}
                    currentPage={pageCurrent}
                    numbersPage={totalPages}
                    size={rowsPerPageInit}
                    annule={(id, isVenteAConfirmerParCourtier) => {
                      changeSelectedVente(id);
                      setOpenAnnuleVente(true);
                      setIsVenteAConfirmerParCourtier(isVenteAConfirmerParCourtier);
                    }}
                  />
                </>
              </Modal>
            </PermissionWrapper>
            {/* modal Valider  */}
            <Modal open={openValidVente} onClose={() => openValidVente(false)}>
              <>
                <PopinValidVente
                  list={listSelectedVente}
                  onClose={() => setOpenValidVente(false)}
                  onClick={(validList) => {
                    setOpenValidVente(false);
                    handleValidVente(validList);
                  }}
                />
              </>
            </Modal>

            {/* modal Annulee  */}
            <PermissionWrapper requiredPermission={["CANCEL_SALE"]}>
              <Modal
                open={openAnnuleVente}
                onClose={() => setOpenAnnuleVente(false)}
              >
                <>
                  <PopinAnnuleVente
                    onClose={() => setOpenAnnuleVente(false)}
                    success={() => handleAnnulationSuccess()}
                    selected={listSelectedVente}
                    authorization={authorization}
                    user={currentUser}
                    list={rows}
                    isVenteAConfirmerParCourtier={
                      isVenteAConfirmerParCourtier ||
                       selectedListContainsVenteAConfirmerParCourtier}
                  />
                </>
              </Modal>
            </PermissionWrapper>

            <div className="button-list">
              <form
                onSubmit={(e) => onEnterApplyFilters(e)}
                className="searchField"
              >
                <TextField
                  placeholder={t("ventes.search")}
                  value={filters.recherche}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      recherche: e.target.value,
                    })
                  }
                  InputProps={{
                    endAdornment: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          cursor: "pointer",
                        }}
                        onClick={() => applyFilters()}
                      >
                        <Search fill={"#2896AF"} />{" "}
                      </div>
                    ),
                  }}
                />
              </form>

              {currentUser?.type !== "CHARGE_CLIENT" && <PermissionWrapper requiredPermission={["VALIDATE_SALE"]}>
                <Button
                  className="blue small square"
                  style={{ marginRight: "8px" }}
                  // disabled={listSelectedVente.length <= 0}
                  disabled={listSelectedVente.length <= 0 || disabledAnnulation}
                  onClick={() => setOpenValidVente(true)}
                >
                  <Active />
                </Button>
              </PermissionWrapper>}

              {currentUser?.type !== "CHARGE_CLIENT" && <PermissionWrapper requiredPermission={["CANCEL_SALE"]}>
                <Button
                  className="blue small square"
                  style={{ marginRight: "8px" }}
                  disabled={listSelectedVente.length <= 0 || disabledAnnulation}
                  onClick={() => setOpenAnnuleVente(true)}
                >
                  <Inactive />
                </Button>
              </PermissionWrapper>}

              {currentUser?.type !== "CHARGE_CLIENT" && <PermissionWrapper requiredPermission={["EXPORT_SALE"]}>
                <Button
                  className="blue small square"
                  style={{ marginRight: "12px" }}
                  disabled={!toggleExport()}
                >
                  {toggleExport() ? <CSVVentes filters={filters} /> : <Dwnld />}
                </Button>
              </PermissionWrapper>}

            </div>
          </div>
        </div>

        <p className="filter_infos">
          {t("ventes.venteFilter")}
          {t("ventes.confirmCourtier")} (
          {allRows?.filter((r) => r.dateAconfirmer && !r.dateAvalider)?.length})
          - {t("ventes.confirmCourtierValidPrimeoP1")}
          {t("ventes.confirmCourtierValidPrimeoP2")} (
          {
            allRows?.filter(
              (r) => r.dateAconfirmer && r.dateAvalider && !r.dateValide
            )?.length
          }
          ) - {t("ventes.validPrimeo")} (
          {allRows?.filter((r) => r.dateValide && !r.dateAnnule)?.length}) -{" "}
          {t("ventes.annulee")} ({allRows?.filter((r) => r.dateAnnule)?.length})
        </p>

        {toggleFilters && (
          <div className="vente_header_filters">
            <div className="vente_header_filters_container">
              {/* Date debut */}
              <div className="datepicker">
                <DatePicker
                  value={filters.startDate}
                  onChange={(e) => {
                    setFilters({ ...filters, startDate: e });
                  }}
                  label={t("ventes.filterLabel.dateStart")}
                  limitation={{
                    max: filters?.endDate ? filters.endDate : undefined,
                  }}
                />
              </div>
              {/* Date fin */}
              <div className="datepicker">
                <DatePicker
                  value={filters.endDate}
                  onChange={(e) => {
                    setFilters({ ...filters, endDate: e });
                  }}
                  label={t("ventes.filterLabel.dateEnd")}
                  limitation={{
                    min: filters?.startDate ? filters.startDate : undefined,
                  }}
                />
              </div>
              {/* Montant Mini */}
              <div className="inputFilter_number">
                <TextInput
                  label={t("ventes.filterLabel.min")}
                  type="number"
                  value={filters.commissionVenteMin}
                  autoComplete="off"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      commissionVenteMin: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    if (
                      !!filters.commissionVenteMax &&
                      !isNaN(filters.commissionVenteMax) &&
                      !isNaN(filters.commissionVenteMin) &&
                      filters.commissionVenteMax <= filters.commissionVenteMin
                    ) {
                      setFilters({
                        ...filters,
                        commissionVenteMin: filters.commissionVenteMax - 1,
                      });
                    }
                  }}
                />
              </div>
              {/* Montant Max */}
              <div className="inputFilter_number">
                <TextInput
                  label={t("ventes.filterLabel.max")}
                  type="number"
                  value={filters.commissionVenteMax}
                  autoComplete="off"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      commissionVenteMax: e.target.value,
                    })
                  }
                  onBlur={(e) => {
                    if (
                      !isNaN(filters.commissionVenteMax) &&
                      !isNaN(filters.commissionVenteMin) &&
                      filters.commissionVenteMax <= filters.commissionVenteMin
                    ) {
                      setFilters({
                        ...filters,
                        commissionVenteMax: ++filters.commissionVenteMin,
                      });
                    }
                  }}
                />
              </div>
              {/* Société courtage */}
              {authorization === authorizations[0] && (
                <div>
                  <ComboBoxObject
                    label={t("ventes.filterLabel.societeCourtage")}
                    canBeCanceled={true}
                    list={listSociete}
                    value={filters.societeCourtage}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        societeCourtage: e,
                      });
                    }}
                  />
                </div>
              )}
              {/* Responsable vendeur*/}
              {(authorization === authorizations[0] ||
                authorization === authorizations[1]) && (
                  <div>
                    <ComboBoxObject
                      label={t("ventes.filterLabel.responsable")}
                      list={listResponsableV}
                      value={filters.responsableVendeur}
                      canBeCanceled={true}
                      onChange={(e) => {
                        selectWithJustResponsable(e);
                      }}
                    />
                  </div>
                )}
              {/* Vendeur */}
              {(authorization === authorizations[0] ||
                authorization === authorizations[1] ||
                authorization === authorizations[2]) && (
                  <div>
                    <ComboBoxObject
                      label={t("ventes.filterLabel.vendeurs")}
                      canBeCanceled={true}
                      list={listVendeurs}
                      value={filters.vendeur}
                      onChange={(e) => {
                        selectWithJustVendeur(e);
                      }}
                    />
                  </div>
                )}
              {/* Région */}
              <div>
                <GeoExclu
                  label={t("ventes.filterLabel.geo")}
                  value={filters.departements}
                  onChange={(e) => {
                    setFilters({ ...filters, departements: e });
                  }}
                />
              </div>
              {/* Offres */}
              <div>
                <ComboBoxObject
                  label={t("ventes.filterLabel.offre")}
                  canBeCanceled={true}
                  list={offres?.map((o) => {
                    return { name: o, value: o };
                  })}
                  value={filters.nomOffre}
                  onChange={(e) => {
                    setFilters({ ...filters, nomOffre: e });
                  }}
                />
              </div>
              {/* Puissance */}
              <div>
                <ComboBoxObject
                  label={t("ventes.filterLabel.puissance")}
                  canBeCanceled={true}
                  list={puissanceObject()}
                  value={filters.puissanceSouscrite}
                  onChange={(e) => {
                    setFilters({ ...filters, puissanceSouscrite: e });
                  }}
                />
              </div>
              {/* Statut Vente */}
              <div>
                <ComboBoxObject
                  label={t("ventes.filterLabel.statut")}
                  canBeCanceled={true}
                  list={listStatutVente}
                  value={filters.statut}
                  onChange={(e) => {
                    setFilters({ ...filters, statut: e });
                  }}
                />
              </div>
            </div>
            <div>
              <Button className="blue" onClick={() => applyFilters()}>
                {t("ventes.filterLabelBtn")}
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* Table */}
      <div className={`vente_content_wrapper ${toggleFilters && "filterShow"}`}>
        <div className="vente_content">
          <VentesTable
            headCells={headCells}
            rows={rows}
            rowsPage={rowsPerPageInit}
            pagination={true}
            pageInit={pageCurrent}
            actions={{
              open: {
                fnc: (v) => handleOpenDetailVente(v),
              },
            }}
            collect={(l) => setListSelectedVente(l)}
            actionRowTri={(isAsc, idForTri) => {
              setFilters({
                ...filters,
                elementTarget: idForTri,
                tri: isAsc,
              });
              handleClickTri();
            }}
          />
        </div>
      </div>
      {/* Pagination */}
      <div className="pagination_wrapper">
        <PaginationManager
          rows={rows}
          pagination={true}
          onChange={handleOnChange}
          numbersPage={totalPages}
          currentPage={pageCurrent}
          size={rowsPerPageInit}
        />
      </div>
    </div>
  );
};

export default Ventes;
