import React, { useEffect, useState } from "react";

// style
import "./confirmSub.scss";
import { ReactComponent as ArrowDownSvg } from "../../core/assets/picto/arrow-down-bloc.svg";
import { ReactComponent as ArrowUpSvg } from "../../core/assets/picto/arrow-up-bloc.svg";

import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import Lien from "../Lien/Lien";
import InputSMS from "../InputSMS/InputSMS";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as saleActions from "../../core/stores/saleStore";
import {
   createOrder,
   createVente,
} from "../../core/services/httpRequest/axios";
import handleRequestResponse from "../../utils/responsesHandler";
import { ENUM_PUISSANCES } from "../../utils/BOCO/enums";
import { trueOrFalseDo } from "../../utils";
import { createTripicaOrderWithSMS } from "../../utils/tripicaOrderWithSMS";
import ErrorCodeListDisplay from "../ErrorCodeList/ErrorCodeListDisplay";

import Loader from "../Loader/Loader";

const ConfirmSub = () => {
   const { t } = useTranslation();
   const history = useHistory();

   // open bloc
   const [isOpen, setIsOpen] = useState(false);

   const [trySMS, setTrySMS] = useState(0);
   const [trySMSActive, settrySMSActive] = useState(true);
   const [code, setCode] = useState("");
   const [errorCode, setErrorCode] = useState(false);
   const [errors, setErrors] = useState([]);
   const [loading, setloading] = useState(false);
   const [loadingSMS, setLoadingSMS] = useState(false);


   const dispatch = useDispatch();

   const broker = useSelector((state) => state.auth.broker);
   const vente = useSelector((state) => state.sale);

   // disable sms send for 30sec after click event
   useEffect(() => {
      const timer = setTimeout(() => {
         settrySMSActive(true);
      }, 30000);
      return () => clearTimeout(timer);
   }, [trySMSActive]);

   //   Store
   const sale = useSelector((state) => state.sale);
   const tel = sale?.customerMobile
      .replace(/(\d{2})/g, "$1 ")
      .replace(/(^\s+|\s+$)/, "");

   const [showCodeZone, setShowCodeZone] = useState(false);
   const [smsValid, setSmsValid] = useState(false);

   const handleClick = () => {
      setIsOpen(!isOpen);
   };

   const handleClickShowCodeZone = () => {
      setLoadingSMS(true);
      settrySMSActive(false);
      const order = createTripicaOrderWithSMS(sale?.preSMSorder, "");
      order.sendMail = false
      createOrder(order).then((res) => {
         return handleRequestResponse(
            res,
            () => {

               dispatch(
                  saleActions.update({
                     target: "idVenteTripica",
                     value: res.data.productOrder.sandBoxUuid,
                  })
               );
               setShowCodeZone(true);
               setLoadingSMS(false);
            },
            () => {
               setShowCodeZone(false);
               setLoadingSMS(false);
            }
         );
      });
   };

   const handleReSendSMS = () => {
      if (trySMS === 4 || trySMSActive === false) {
         return;
      }
      handleClickShowCodeZone();
      setTrySMS(trySMS + 1);
      settrySMSActive(false);
      setErrors([])
   };

   // return value input SMS
   const codeSms = (valueCode) => {
      setCode(valueCode);
      setErrorCode(false);
   };

   // check if input code sms is complet
   useEffect(() => {
      if (code.length === 4) {
         setSmsValid(true);
      } else {
         setSmsValid(false);
      }
   }, [code]);

   const handleClickValidation = (data) => {
      setloading(true);
      setErrors([]);
      setErrorCode(false);

      let listVenteParProduit = sale?.optionStorage?.map((o) => {
         return {
            produit: o?.productSpecification?.productNumber,
         };
      });

      let CAR = trueOrFalseDo(
         sale?.PDLInfos?.estimatedCar?.carBase?.value,
         () => {
            let conso = sale?.PDLInfos?.estimatedCar?.carBase?.value;

            return conso;
         },
         () => {
            return 0;
         }
      );

      let vente = {
         codeSMS: code,
         idVenteTripica: sale.idVenteTripica,
         prenomClient: data.givenName,
         nomClient: data.familyName,
         emailClient: sale?.customerEmail,
         estimationCar: CAR,
         voltalis : sale?.voltalis,
         streetPDL:  [data.PDLInfos.deliveryAddress.housenumber,data.PDLInfos.deliveryAddress.street, data.PDLInfos.deliveryAddress.additionnalAddress].join(' ')  ,
         codePostalPDL: data.PDLInfos.deliveryAddress.zipCode,
         villePDL: data.PDLInfos.deliveryAddress.city,
         nomOffre: sale.offerStorage.name,
         idOffre: 0, // sale.offerStorage.productNumber
         puissancePDL: ENUM_PUISSANCES.find(
            (p) => p.number === data.PDLInfos.contractPowerMax.valeur
         ).enum,
         dateCreationVente: JSON.parse(data.timeStamp),
         statut: "A_CONFIRMER_COURTIER",
         dateAconfirmer: data.dateAconfirmer,
         dateAvalider: data.dateAvalider,
         dateValide: data.dateValide,
         dateAnnule: data.dateAnnule,
         motifAnnulation: data.motifAnnulation,
         jourRendezVous: data.jourRendezVous,
         periodeRendezVous: data.periodeRendezVous,
         idVendeur: broker.idUtilisateur,
         commissionVente: data.commissionVente || 0,
         montantDecommissionement: data.commissionVente || 0,
         renounceRetractation: data.renounceRetractation,
         listVenteParProduit: listVenteParProduit,
         estimatedMonthlyPayment:
            sale?.valueMonthSelected === "NaN"
               ? (sale?.monthlyAmount / 100)?.toFixed(2)
               : (sale?.valueMonthSelected / 100)?.toFixed(2),
      };

      return createVente(vente).then((res) => {
         return handleRequestResponse(
            res,
            () => {
               dispatch(
                  saleActions.update({
                     target: "saleTCOStep",
                     value: ["SUBSCRIPTION", "RECAP", "VALIDATION"],
                  })
               );
               dispatch(
                  saleActions.update({
                     target: "saleValid",
                     value: true,
                  })
               );
               dispatch(
                  saleActions.update({
                     target: "acceptedSale",
                     value: res.data,
                  })
               );
               setloading(false);
               history.push("/validation");
            },
            () => {
               setloading(false);
               setErrors(["BAD_ORDER_VALIDATION_CODE"]);
               setSmsValid(false);
               setErrorCode(true);

            }
         );
      });
   };

   const [screenSize, setScreenSize] = useState(window.innerWidth);

   const detectSize = () => {
      setScreenSize(window.innerWidth);
   };

   useEffect(() => {
      window.addEventListener("resize", detectSize);

      return () => {
         window.removeEventListener("resize", detectSize);
      };
   }, [screenSize]);

   return (
      <div className="container_confirmSub">
         {screenSize < 1460 && (
            <div className="arrow-corner">
               {isOpen ? <ArrowUpSvg /> : <ArrowDownSvg />}
            </div>
         )}
         <header onClick={handleClick} className={`${isOpen ? "isOpen" : ""}`}>
            <h2>{t("confirmSub.title")}</h2>
         </header>

         {(isOpen || screenSize > 1460) && (
            <main>
               <p className="subTitle">{t("confirmSub.text")}</p>

               <div className="container_lineSms">
                  <div className="text_bloc">
                     <h3>{t("confirmSub.titleCodeValid")}</h3>
                     {trySMS === 0 && (
                        <p>
                           {showCodeZone
                              ? t("confirmSub.textInfo2") + " : "
                              : t("confirmSub.textInfo") + " : "}
                           <span className={`bold ${!showCodeZone && "blue"}`}>
                              {tel}
                           </span>
                        </p>
                     )}
                     {trySMS > 0 && (
                        <>
                           <p>
                              {t("confirmSub.textInfoAfterSMS1")}
                              {t(`confirmSub.try${trySMS}`)}
                           </p>
                           <p>
                              au <span className="bold">{tel}</span>
                           </p>
                        </>
                     )}
                     {showCodeZone && screenSize < 1460 && (
                        <p>
                           {t("confirmSub.textInfoAfterSMS2") + " "}
                           <Lien
                              onClick={handleReSendSMS}
                              disabled={trySMSActive}
                           >
                              {t("confirmSub.textSMSRetry")}
                           </Lien>
                        </p>
                     )}
                  </div>
                  <div className="validation_code">
                     {!showCodeZone && !loadingSMS && (
                        <Button
                           className="green"
                           onClick={handleClickShowCodeZone}
                        >
                           {t("confirmSub.labelAction")}
                        </Button>
                     )}
                     {loadingSMS && (
                        <div
                           style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                        >
                           <Loader />
                        </div>
                     )}
                     {showCodeZone && !loadingSMS && (
                        <>
                           <InputSMS onChange={codeSms} error={errorCode} />
                           {errorCode && (
                              <p className="error_message">
                                 {t("confirmSub.errorCode")}
                              </p>
                           )}
                           {screenSize > 1460 && (
                              <p>
                                 {t("confirmSub.textInfoAfterSMS2") + " "}
                                 <Lien
                                    onClick={handleReSendSMS}
                                    disabled={trySMSActive}
                                 >
                                    {t("confirmSub.textSMSRetry")}
                                 </Lien>
                              </p>
                           )}
                        </>
                     )}
                  </div>
               </div>
               {showCodeZone && (
                  <>
                     <ErrorCodeListDisplay errors={errors} />
                     <div className="wrappe_btn">
                        {loading && (
                           <div
                              style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 alignItems: "center",
                              }}
                           >
                              <Loader />
                           </div>
                        )}
                        {!loading && (
                           <Button
                              className="green"
                              icon="check"
                              disabled={!smsValid}
                              onClick={() => handleClickValidation(vente)}
                           >
                              {t("confirmSub.labelConfirmSub")}
                           </Button>
                        )}
                     </div>
                  </>
               )}
            </main>
         )}
      </div>
   );
};

export default ConfirmSub;
