const fr = {
  lang: "français",
  actions: {
    save: "enregistrer",
    search: "Rechercher",
    annul: "Annuler la vente",
    annul2: "Souhaitez-vous annuler la vente ?",
    disconnect: "Déconnexion",
    disconnect2: "Souhaitez-vous vous déconnecter ?",
  },
  common: {
    addressPlaceholder: "Aucune option disponible",
    loading: "Chargement ...",
    ADMIN_PRIMEO: "Admin Primeo",
    ADMIN_COURTAGE: "Admin courtage",
    RESPONSABLE_VENDEUR: "Responsable vendeur",
    VENDEUR: "Vendeur",
    CHARGE_CLIENT: "Chargé client",
    ACTIF: "Actif",
    INACTIF: "Inactif",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
  },
  error: {
    required: "Ce champ est requis",
    dateNotInRange: "Date invalide. Contacter Primeo pour souscrire",
    invalidDate: "Date invalide",
    invalidBirthdate: "Contactez Primeo pour souscrire",
    payment: {
      ibanError: "L’IBAN saisi est non valide",
    },
    mail: "Le format de l'adresse email est incorrect",
    name: "Le nom ne doit contenir que des lettres",
    iban: "L’IBAN saisi est non valide",
    tva: "Le numéro de TVA est incorrect",
    invalidPhone: "Numéro invalide",
    phoneLength: "Le numéro doit comporter 10 chiffres",
    internationalPhoneLength: "Le numéro doit comporter 12 caractères",
    mustBeMobilePhone: "Le numéro doit commencer par 06 ou 07",
    mustBeInternationalMobilePhone: "Le numéro doit commencer par +336 ou +337",
    mustbe0to100: "Le pourcentage doit être compris entre 0 et 100%",
    backhome: "REVENIR SUR LA PAGE D'ACCUEIL",
    title403:
      "Erreur 403. Vous n'avez pas les autorisations nécessaires pour accéder à ce contenu.",
    sub403:
      "Vous essayez d'accéder à un contenu ou à une ressource à laquelle vous n'avez pas accès. Pas d'inquiétude, vous pouvez revenir sur la page d'accueil pour retrouver votre chemin.",
    title404: "Erreur 404. La page que vous recherchez semble introuvable.",
    sub404:
      "Il est possible qu'une erreur se soit glissée dans votre recherche de page. Pas d'inquiétude, vous pouvez revenir sur la page d'accueil pour retrouver votre chemin.",
  },
  auth: {
    title: "Bienvenue !",
    subtitle: "S'identifier en saisissant les informations ci-dessous",
    email: "Email",
    password: "Mot de passe",
    rememberMe: "Se souvenir de moi",
    connect: "SE CONNECTER",
    forgotPassword: "Mot de passe oublié ?",
    incorrect: "Login/mot de passe incorrect.",
    inactive: "Veuillez contacter votre responsable de société.",
  },
  password: {
    back: "RETOUR",
    send: "ENVOYER",
    connect: "SE CONNECTER",
    continue: "CONTINUER",
    change: "CHANGER LE MOT DE PASSE",
    new: "Saisir votre nouveau mot de passe",
    repete: "Confirmer votre nouveau mot de passe",
    maj: "Une majuscule",
    min: "Une minuscule",
    number: "Un chiffre",
    spec: "Un caractère spécial",
    minlength: "8 caractères",
    step1: "Changement ou réinitialisation du mot de passe",
    step1sub: "Envoyer un e-mail de réinitialisation du mot de passe",
    step1info1:
      "Si vous ne recevez pas d'e-mail, assurez-vous d'avoir utilisé l'adresse avec laquelle vous vous êtes inscrit et vérifiez votre dossier spam.",
    step1info2:
      "Si vous réinitialisez votre mot de passe plusieurs fois, seul le lien de réinitialisation de mot de passe le plus récent sera valide.",
    step2:
      "L’e-mail de changement ou de réinitialisation du mot de passe a été envoyé !",
    step2sub:
      "Un e-mail vous a été envoyé avec les informations nécessaires pour modifier votre mot de passe.",
    step3: "Nouveau mot de passe",
    step3sub: "Choisir votre nouveau mot de passe",
    nomatch: "Les mots de passe ne correspondent pas",
    formaterror: "Mauvais format",
    step4: "Votre nouveau mot de passe a été créé avec succès !",
    step4sub: "Se connecter dès maintenant",
  },
  accueil: {
    titre: "Bienvenue sur votre outil de souscription Primeo Energie",
    text: "Accéder à votre espace pour gérer vos ventes ou bien démarrer une vente avec un client.",
    bouton: "Commencer la vente",
    labelBtnToEC: "Accéder à son espace",
  },
  souscription: {
    pdl: {
      title: "PDL",
      text: "Le prospect atteste être l’occupant habituel du logement pour lequel il souscrit une offre de fourniture d’énergie et autorise Primeo Energie et ses partenaires à consulter ses données historiques de consommation auprès du gestionnaire de réseau de distribution (Enedis)",
      yes: "Oui",
      no: "Non",
      placeholder: "Référence du Point de livraison",
      address: "Adresse du pdl",
      conso: "Consommation annuelle estimée",
      PS: "Puissance souscrite",
      errorNotFound: "La référence saisie n’a pas été trouvée",
      calendrier: "Calendrier fournisseur actuel",
      PDLSGTErrorPart1: "Erreur Enedis ",
      PDLSGTErrorPart2:
        " : Ressayez plus tard ou contactez l'administrateur si l'erreur persiste.",
    },
    offre: {
      title: "Offre & services",
      cgvtitle: "CGV",
      optionTitle: "Services",
      optionPriceTitle: "Options tarifaires",
      optionBase: "Base",
      optionHours: "Heures pleines / creuses",
      btnSelection: "Sélectionner",
      btnSelectionnee: "Sélectionnée",
      titleAbonnement: "Abonnement",
      basePrixTitle: "Prix (Base)",
      heurePleineTitle: "Prix (Heures pleines)",
      heureCreuseTitle: "Prix (Heures creuses)",
      unitAbonnement: "€ TTC/mois",
      unitTarif: "€ TTC/kWh",
      unitOption: "€ TTC/mois",
      unitAbonnementRecap: "/mois",
      unitTarifRecap: " TTC/kWh",
      unitOptionRecap: " TTC/mois",
      EUR: "€",
      option1: "Energie renouvelable",
      option2: "Service dépannage",
      option3: "Garantie électroménager",
      option1Premium: "Energie renouvelable",
      option2Premium: "Service dépannage",
      option3Premium: "Garantie électroménager",
      optionVoltalis: "Thermostat connecté Voltalis",
      incluseOffre: "incluse dans l’offre",
      titleRemise: "Remises",
      consoPartVariableBaseTTC: "Consommation Base",
      consoPartVariableHcTTC: "Consommation HC",
      consoPartVariableHpTTC: "Consommation HP",
      consoPartVariableHphTTC: "Consommation HPH",
      consoPartVariableHpeTTC: "Consommation HPE",
      consoPartVariableHchTTC: "Consommation HCH",
      consoPartVariableHceTTC: "Consommation HCE",
      consoPartVariableUnknownTTC: "Consommation",
    },
    id: {
      title: "Identité",
      MALE: "Monsieur",
      validTitulaire: "Le client certifie être le titulaire du contrat",
    },
    facturation: {
      title: "Mode de facturation",
      address: "Adresse de facturation",
      placeholderHouseNumber: "Numéro, bis, etc",
      placeholderHousenumberAndStreet: "Numéro et voie",
      placeholderStreet1: "Rue, voie, ...",
      placeholderHamlet: "Lieu dit",
      placeholderAdditionnalAddress1: "N° App ou BAL - Etage - Couloir - Esc",
      placeholderAdditionnalAddress2: "Entrée - Bâtiment - Immeuble - Résidence",
      placeholderZip: "Code postal",
      placeholderCity: "Ville",
      mode: "Mode de facturation",
      conso: "",
      consoR: "Mensuelle au réel",
      consoE: "Annuelle avec échéancier",
      factuR: "Facturation réelle",
      factuE: "Facturation estimée",
      pdlAddress: "Adresse du PDL",
      otherAddress: "Utiliser une autre adresse",
      addressPlaceholder: "Adresse de facturation",
      date: "Date souhaitée de début du contrat",
      earlier: "Le plus tôt possible",
      choose: "Choisir une date ",
      plannedDate: "Date prévue",
      renounce: "Le client renonce à son droit de rétractation de 14 jours.",
    },
    paiement: {
      title: "Moyen de paiement",
      SEPAtitle: "Prélèvement sepa",
      inputIbanLabel: "IBAN",
      inputDesirDate: "Date de prélèvement souhaitée",
      legalText:
        "Le prospect autorise Primeo Energie France à prélever sur le compte bancaire désigné ci-dessus les sommes dues au titre du contrat selon les modalités prévues. Un prélèvement test de 10€ sera effectué dans les 5 jours suivant sa demande de souscription, sous réserve de la signature du mandat de prélèvement. Ce montant sera automatiquement déduit de sa première facture. ",
      checkboxLabelValid:
        "Oui (validation mandat par code unique sms à l’étape suivante)",
      titleUserBank: "Titulaire du compte",
    },
  },
  recap: {
    title: "Récap. vente en cours",
    unitConso: "kWh",
    titlePower: "Puissance",
    unitPower: "kVA",
    unitSub: "€ TTC/mois",
    titleMensualites: "Ajuster les mensualités",
    labelButton: "Valider la saisie",
    sendQuote: "Envoyer le devis",
    messageItem1: "Attention régularisation attendue plus élevée",
    messageItem2: "Montant de régul. théorique attendu : 1 mensualité",
    messageItem3: "Régul. attendue moins élevée, voire remboursement",
    unitPriceAnnuel: "€ TTC/an",
  },
  confirmSub: {
    title: "Confirmer la souscription",
    text: "Le prospect certifie l’exactitude des informations fournies et reconnait avoir préalablement lu et accepté les Conditions Générales d’Utilisation, le prix de la mise en service, Conditions Générales de Vente et pris connaissance du traitement de mes données tel que décrit dans la Politique de Confidentialité de Primeo Energie.",
    titleCodeValid: "Code de validation sms",
    textInfo: "Envoyer le SMS de vérification au",
    textInfo2: "Le code a été envoyé au",
    labelAction: "Envoyer le SMS",
    textInfoAfterSMS1: "Le code a été envoyé ",
    textInfoAfterSMS2: "SMS non reçu ?",
    textSMSRetry: "Renvoyer le SMS",
    labelConfirmSub: "Confirmer la souscription",
    try1: "une seconde fois",
    try2: "une troisième fois",
    try3: "une quatrième fois",
    try4: "une cinquième fois",
    errorCode: "Code incorrect",
  },
  recapPage: {
    title: "Récapitulatif",
    messageItem1: "Vous avez rencontré aujourd’hui le ",
    messageItem2:
      " à domicile / par téléphone et vous avez compris avoir souscrit à un contrat d’énergie Electricité auprès de Primeo Energie dans le respect des règles légales du démarchage.",
    messageItem3:
      " En souscrivant à cette offre, vous acceptez d’être contacté par votre fournisseur pour vérifier l’exactitude des informations fournies ainsi qu’avoir pris connaissance des éléments suivants :",
    offerItem: "Offre Primeo",
    mensualite: "Mensualité",
    mensualiteUnit: "€ TTC",
    coord: "Coordonnées",
    consoLocation: "Lieu de consommation",
    facturLocation: "Adresse de facturation",
    caracteristicsTilte: "Caractéristiques techniques",
    caracteristicsText:
      "Caractéristiques techniques sous réserve de confirmation par le gestionnaire du réseau",
    refPDL: "Ref. PDL",
    PS: "Puissance souscrite",
    option: "Option tarifaire",
    conso: "Consommation annuelle estimée",
    // PS: "Puissance souscrite",
    errorNotFound: "La référence saisie n’a pas été trouvée",
    calendrier: "Calendrier fournisseur actuel",
    contractTitle: "Contrat",
    offer: "Offre",
    factur: "Facturation",
    facturEstimee:
      "Mensualités fixes d’après conso. annuelle estimée (régul. 12e mois)",
    facturReel: "Mensualités au réel d'après les relevés du distributeur",
    paiement: "Mode de paiement",
    paiementDefault: "Prélèvement bancaire",
    date: "Date souhaitée de début de fourniture",
    sepaTitle: "Mandat de prélèvement SEPA",
    creditor: "Créancier",
    debtor: "Compte à débiter",
    iban: "IBAN : ",
    ref: "Référence unique de mandat (RUM) : figure sur les documents contractuels disponibles dans l’Espace client ",
    annuel: "Annuité",
    annuelUnit: "€ TTC/an",
  },
  offre: {
    title: "Offre & services",
    cgvtitle: "CGV",
    optionTitle: "Services",
    optionPriceTitle: "Options tarifaires",
    optionBase: "Base",
    optionHours: "Heures pleines / creuses",
    btnSelection: "Sélectionner",
    btnSelectionnee: "Sélectionnée",
    titleAbonnement: "Abonnement",
    basePrixTitle: "Prix (Base)",
    heurePleineTitle: "Prix (Heures pleines)",
    heureCreuseTitle: "Prix (Heures creuses)",
    unitAbonnement: "€ TTC/mois",
    unitTarif: "€ TTC/kWh",
    unitOption: "€ TTC/mois",
    unitAbonnementRecap: "/mois",
    unitTarifRecap: " TTC/kWh",
    unitOptionRecap: " TTC/mois",
    EUR: "€",
    option1: "Energie renouvelable",
    option2: "Service dépannage",
    option3: "Garantie électroménager",
    option1Premium: "Energie renouvelable",
    option2Premium: "Service dépannage",
    option3Premium: "Garantie électroménager",
    incluseOffre: "incluse dans l’offre",
    titleRemise: "Remises",
  },
  id: {
    title: "Identité",
    MALE: "Monsieur",
  },
  facturation: {
    title: "Mode de facturation",
    address: "Adresse de facturation",
    placeholderHouseNumber: "Numéro, bis, etc",
    placeholderHousenumberAndStreet: "Numéro et voie",
    placeholderStreet1: "Rue, voie, ...",
    placeholderStreet2: "Bâtiment, étage, appartement",
    placeholderZip: "Code postal",
    placeholderCity: "Ville",
    mode: "Mode de facturation",
    conso: "",
    consoR: "Mensuelle au réel",
    consoE: "Annuelle avec échéancier",
    factuR: "Facturation réelle",
    factuE: "Facturation estimée",
    pdlAddress: "Adresse du PDL",
    otherAddress: "Utiliser une autre adresse",
    addressPlaceholder: "Adresse de facturation",
    date: "Date souhaitée de début du contrat",
    earlier: "Le plus tôt possible",
    choose: "Choisir une date ",
    plannedDate: "Date prévue",
    renounce: "Le client renonce à son droit de rétractation de 14 jours.",
  },
  paiement: {
    title: "Moyen de paiement",
    SEPAtitle: "Prélèvement sepa",
    inputIbanLabel: "IBAN",
    inputDesirDate: "Date de prélèvement souhaitée",
    legalText:
      "Le prospect autorise Primeo Energie France à prélever sur le compte bancaire désigné ci-dessus les sommes dues au titre du contrat selon les modalités prévues. Un acompte de souscription de 10€ sera prélevé dans les 5 jours suivant sa demande de souscription. Ce montant sera automatiquement déduit de sa première facture.",
    checkboxLabelValid:
      "Oui (validation mandat par code unique sms à l’étape suivante)",
  },
  validation: {
    title: "La vente a bien été créée.",
    text1:
      "La vente est désormais disponible dans votre outil de gestion afin de gérer et vérifier celle-ci.",
    subtitle: "PLANIFIER L'APPEL DE BIENVENUE",
    text2:
      "Un appel de bienvenue afin de confirmer la souscription avec le client.",
    selectDate: "Sélectionner un jour",
    selectTimeSlot: "Sélectionner un créneau",
    slot1: "Matin",
    slot2: "Midi",
    slot3: "Après-midi",
    slot4: "Soir",
    confirm: "Confirmer le rendez-vous",
    confirmed: "RDV CONFIRMÉ",
    back: "Retour à l'accueil",
  },
  geoComp: {
    label: "Exclusivité géographique",
    all: "Tout sélectionner",
    notAll: "Tout désélectionner",
  },
  adduser: {
    title: "Ajouter un utilisateur",
    subtitle: "Lorem ipsum dolor sit amet consectetur",
    type: "Type :",
    type1: "Admin Primeo",
    type2: "Admin Courtage",
    type3: "Responsable vendeur",
    type4: "Vendeur",
    labelFamilyName: "Nom",
    labelGivenName: "Prénom",
    labelEmail: "Address Email",
    labelPhone: "Numéro de téléphone",
    labelResp: "Responsable",
    labelBusiness: "Société",
    labelComm: "Pourcentage commission",
    labelTypeVendeur: "Type vendeur",
    visuCommission: "Visu commission : ",
    remote: "Télévente",
    door2door: "Porte à porte",
    both: "Les deux",
    yes: "OUI",
    no: "NON",
    labelStatut: "Statut",
    active: "Actif",
    inactive: "Inactif",
    save: "Enregistrer",
  },
  modifyuser: {
    title: "Modifier un utilisateur",
    subtitle: "Lorem ipsum dolor sit amet consectetur",
    type: "Type :",
    type1: "Admin Primeo",
    type2: "Admin Courtage",
    type3: "Responsable vendeur",
    type4: "Vendeur",
    labelFamilyName: "Nom",
    labelGivenName: "Prénom",
    labelEmail: "Address Email",
    labelPhone: "Numéro de téléphone",
    labelResp: "Responsable",
    actualResp: "Responsable actuel*",
    labelNewResp: "Nouveau Responsable",
    team: "Équipe",
    transferTo: "Transférer à :",
    labelBusiness: "Société",
    labelComm: "Pourcentage commission",
    visuCommission: "Visu commission : ",
    yes: "OUI",
    no: "NON",
    labelStatut: "Statut",
    active: "Actif",
    inactive: "Inactif",
    save: "Enregistrer",
  },
  users: {
    title: "Liste des utilisateurs",
    filters: "Filtres",
    filter: "FILTRER",
    add: "AJOUTER",
    societies: "Sociétés",
    users: "Utilisateurs :",
    active: "Actif",
    inactive: "Inactif",
    lastname: "Nom",
    name: "Prénom",
    society: "Société",
    email: "Email",
    respo: "Responsable",
    noRespo: "Aucun responsable",
    type: "Profil",
    statut: "Statut",
    transferTooltip:
      "Les utilisateurs sélectionnés doivent faire parti de la même société pour pouvoir ouvrir la fenetre de transfert",
    typeVendeur: "Type vendeur",
  },
  addsociete: {
    add: "AJOUTER",
    save: "Enregistrer",
    title: "Ajouter une société",
    titleModif: "Modifier une société",
    listTitleSociete: "Liste des sociétés",
    subtitle: "L'admin de la société doit déjà avoir été créé",
    titleTab1: "Données générales",
    titleTab2: "Rémunération",
    labelAdministrateur: "Administrateur de cette société (admin courtage)",
    labelName: "Nom de la société",
    labelType: "Type (Ex : Apporteur d'affaires externes)",
    labelSiret: "SIRET",
    labelTva: "N°TVA intracommunautaire",
    labelRcs: "RCS",
    labelCapital: "Capital social (€)",
    labelNaf: "Code NAF",
    labelAdresse: "Adresse",
    labelConvention: "Type de convention",
    labelDateSignature: "Date de signature convention",
    labelDateFinConvention: "Date de fin de convention",
    titleBonus: "Bonus/malus société",
    titleRatios: "Ratios de rémunération par profil",
    labelProfil: "Profil",
    labelRatio: "Ratio",
    titleRole_ADMIN_COURTAGE: "Admin courtier",
    titleRole_RESPONSABLE_VENDEUR: "Responsable vendeurs",
    titleRole_VENDEUR: "Vendeur",
    titleCrossSelling: "Rémunération Cross Selling",
    labelProduit: "Produit",
    labelRemunerationProduit: "Rémunération en €",
    labelPuissanceRemuneration: "kVA  Rémunération en €",
    errorSiret: "Le SIRET doit contenir 14 chiffres",
  },
  notifications: {
    UPDATE_PERMISSIONS: "Permissions modifiées",
    createUserSuccess: "Utilisateur créé",
    modifyUserSuccess: "Utilisateur modifié",
    createSocieteSuccess: "Société créée",
    modifySocieteSuccess: "Société modifiée",
    modifyContratSuccess: "Contrat modifié",
    modifySaleSuccess: "Vente modifié",
    validContrat: "Confirmation de la vente",
    validContratMass: "Confirmations des ventes",
    transferMassSuccess: "Transfert effectué",
    modifyMassSuccess: "Modification(s) effectuée(s)",
    modifyMassStatusACTIF: "Statut activé",
    modifyMassStatusINACTIF: "Statut désactivé",
    annulationVente: "Annulation",
    forVentes: "Pour la/les vente(s) :",
    createAdminNote: "Notes internes créées",
    modifyAdminNote: "Notes internes modifiées",
    500: "Une erreur est survenue",
    403: "Vous ne disposez pas des droits necessaires",
    404: "404",
    406: "Vous n'avez pas les droits pour effectuer cette action.",
    400: "400: BAD REQUEST",
    forUsers: "Pour le(s) profil(s) suivant(s):",
    forSocietes: "Pour le(s) société(s) suivante(s):",
    forContrats: "Pour le(s) contrat(s) suivant(s):",
    changeCurrentAuthorization:
      "Attention, vous serez déconnecté après l'enregistrement du changement",
    changeCurrentAuthorization2:
      "Changement de permissions : vous serez déconnecté après l'enregistrement de la modification",
  },
  societe: {
    title: "Liste des sociétés",
    nom: "Nom",
    type: "Type",
    statut: "Statut",
    societies: "Sociétés :",
  },
  transfermass: {
    title: "Transférer les utilisateurs",
    subtitle: "Sélectionnez le nouveau responsable des vendeurs",
    previousRespo: "Responsables précédents :",
  },
  ventes: {
    title: "Listes des ventes",
    societe: "Société",
    idvente: "ID",
    client: "Client",
    adresse: "CP localité PDL",
    offre: "Offre",
    puissance: "Puissance (kVA)",
    com: "Com. (€)",
    dateVente: "Date de vente",
    statut: "Statut-Date",
    dateRdv: "Date rendez-vous",
    venteFilter: "Ventes : ",
    confirmCourtier: "A confirmer par courtier",
    confirmCourtierValidPrimeoP1: "Confirmée par courtier, ",
    confirmCourtierValidPrimeoP2: "à valider par Primeo",
    validPrimeo: "Validée Primeo",
    annulee: "Annulée",
    filterLabelBtn: "Filtrer",
    search: "Recherche...",
    filterLabel: {
      dateStart: "Date de début",
      dateEnd: "Date de fin",
      min: "Montant min",
      max: "Montant max",
      responsable: "Responsables vendeurs",
      vendeurs: "Vendeurs",
      geo: "Région - Dept.",
      offre: "Offres",
      puissance: "Puissance kVA",
      statut: "Statut de la vente",
      societeCourtage: "Société de courtage",
      respos: "Resp. vendeurs",
      offers: "Offres",
      regions: "Régions",
      societies: "Sociétés",
      chartTitle: "Tableau des ventes",
      weekly: "Vue Hebdomadaire",
      monthly: "Vue Mensuelle",
      annually: "Vue Annuelle",
      totalPrice: "Total montant en € par jour",
      totalSales: "Nombre de ventes par jour",
      week: "SEMAINE",
      month: "mois",
      year: "ANNÉE",
      0: "Janvier",
      1: "Février",
      2: "Mars",
      3: "Avril",
      4: "Mai",
      5: "Juin",
      6: "Juillet",
      7: "Août",
      8: "Septembre",
      9: "Octobre",
      10: "Novembre",
      11: "Décembre",
    },
  },
  detailvente: {
    "CONSOMMATION REELLE": "Consommation réelle",
    "CONSOMMATION ESTIMEE": "Consommation estimée",
    denyRightOfWithdrawal: "A renoncé à son droit de rétractation",
    title: "Détail de la vente",
    id: "ID",
    idTripica: "ID Tripica :",
    dateVente: "Date création de la vente :",
    courtier: "Courtier :",
    responsableVendeur: "Responsable  vendeur :",
    motif: "Statut (motif) - Date :",
    offer: "Offre :",
    opttarif: "Option tarifaire :",
    PS: "Puissance souscrite :",
    refPDL: "Ref. PdL :",
    estimatedCar: "Consommation annuelle estimée",
    phone: "Téléphone :",
    gender: "Civilité :",
    client: "Client :",
    birthdate: "Date de naissance :",
    email: "Email :",
    modefacturation: "Mode de facturation :",
    pdlAddress: "Adresse PDL :",
    facturAddress: "Adresse de facturation :",
    contratstart: "Date de début de contrat :",
    prlvdate: "Date de prélèvement :",
    estmensuelle: "Estimation mensuelle :",
    opts: "Services souscrits :",
    commission: "Commission vente :",
    modifyvente: "Modifier la vente",
    confirmodifyvente: "Enregistrer la vente",
    wantannulvente: "Quelle action souhaiteriez vous réaliser sur la vente ?",
    wantconfirmvente: "Quelle action souhaiteriez vous réaliser sur la vente ?",
    annulvente: "Annuler la vente",
    confirmvente: "Confirmer la vente",
    savevente: "Enregistrer la vente",
    MALE: "Monsieur",
    FEMALE: "Madame",
  },
  pagination: {
    precedent: "Préc.",
    suivant: "Suiv.",
    perPage: "Résultat par page :",
  },
  popinAnnulation: {
    title: "Annulation de vente",
    retour: "Retour",
    confirm: "Confirmer l'annulation",
    subtitleVenteOne: "vente à annuler",
    subtitleVentes: "ventes à annuler",
    idTitle: "ID",
    motifLabel: "Motif d'annulation",
    titleValCom: "Valeur totale théorique de la commission",
    montantDecom: "Montant à appliquer pour décommissionnement",
  },
  popinValidation: {
    title: "Confirmation de ventes",
    retour: "Retour",
    confirm: "Confirmer ventes",
    idTitle: "ID",
  },
  dash: {
    welcome: "Bienvenue",
    lastconnexion: "Dernière connexion le ",
    dateStart: "Date de début",
    dateEnd: "Date de fin",
    vendeurs: "Vendeurs",
    respos: "Resp. vendeurs",
    offers: "Offres",
    regions: "Régions",
    societies: "Sociétés",
    chartTitle: "Tableau des ventes",
    weekly: "Vue Hebdomadaire",
    monthly: "Vue Mensuelle",
    annually: "Vue Annuelle",
    totalPrice: "Total montant en € par jour",
    totalSales: "Nombre de ventes par jour",
    week: "SEMAINE",
    month: "mois",
    year: "ANNÉE",
    0: "Janvier",
    1: "Février",
    2: "Mars",
    3: "Avril",
    4: "Mai",
    5: "Juin",
    6: "Juillet",
    7: "Août",
    8: "Septembre",
    9: "Octobre",
    10: "Novembre",
    11: "Décembre",
  },
  parametrage: {
    title: "Paramétrage",
    title_droits: "Paramétrage des droits",
    title_wording: "Paramétrage des notes internes",
    submit: "Enregistrer",
    cancel: "Reinitialiser",
  },
  errorCodeList: {
    undefined: "",
    BAD_ORDER_VALIDATION_CODE:
      "Le code saisi ne correspond pas à celui envoyé. ",
    WRONG_VALUE: "La valeur dʼune caractéristique nʼest pas correcte",
    NOT_EXPECTED:
      "Une caractéristique est présente sur un Product alors quʼelle ne devrait pas y être",
    NO: "Une caractéristique d'un Product obligatoire nʼest pas présente",
    NOT_AUTHORIZED_CHARAC_ORDER:
      "Aucune caractéristique nʼest autorisée sur ce type de ProductOrder ou la caractéristique donnée nʼest pas autorisée sur ce type de ProductOrder",
    BAD_FORMATTED: "Mauvais format de caractéristique défini dans le catalog",
    LENGTH_OUT_OF_RANGE:
      "La caractéristique ne respecte pas la longueur attendue pour une STRING valueFrom et valueTo définies dans le catalog",
    VALUE_OUT_OF_RANGE:
      "La caractéristique ne respecte pas les valeurs pour une valeur numérique valueFrom et valueT définies dans le catalog",
    BAD_FORMAT:
      "La caractéristique ne respecte pas le format attendu (numérique, null etc)",
    RETIRED_OFFER:
      "Le ProductOffering dans le ProductOrder nʼest plus disponible dans le catalog",
    DEPENDENCY_PB:
      "Il y a un problème de relationship entre des Product (DEPENDENCY, EXCLUSIVITY etc)",
    PRICE_PB: "Le prix dʼun Product est incohérent avec le catalog",
    EMAIL_ALREADY_USED:
      "Lʼemail utilisé pour souscrire le client existe déjà dans le système",
    DISABLED_LOGIN: "Le login utilisé pour faire la souscription est désactivé",
    EMPTY_PRODUCT_ORDER: "Le ProductOrder ne contient aucun OrderItem",
    MANY_TIME_SAME_PRODUCT_IN_ORDER:
      "Un même Product est présent plusieurs fois",
    NO_PASSWORD: "Aucun mot de passe trouvé sur le produit LOGIN",
    PASSWORD_STRENGTH_TOO_LOW: "Le mot de passe choisi nʼest pas assez robuste",
    NO_SUBSCRIPTION:
      "Le Product ELECTRICITE_SOUSCRIPTION nʼa pas été trouvé dans le ProductOrder",
    NO_PLAN:
      "Le Product ELECTRICITE_PLAN nʼa pas été trouvé dans le ProductOrder",
    NO_PRIMARY_RESOURCE:
      "Le Product ELECTRICITE_POINT_DE_LIVRAISON nʼa pas été trouvé dans le ProductOrder",
    NO_CAR:
      "Aucune caractéristique de CAR nʼa été renseigné. Il faut au moins en renseigner une",
    MISSING_LOGIN_PRODUCT: "Il manque le Product LOGIN",
    PDL_ALREADY_IN_ACTIVATION:
      "Le numéro de PDL choisi est déjà dans le système triPica et il est en cours dʼactivation",
    ACTIVATION_DATE_NOT_AT_MIDNIGHT:
      "La date de début de contrat ( activationDate ) n'est pas à minuit (Timezone Europe/Paris)",
    ACTIVATION_DATE_OUT_OF_RANGE:
      "La date de début de contrat ( activationDate ) nʼest pas dans la fenêtre autorisée (trop dans le passé ou dans le futur)",
    ETAT_CONTRACTUEL_NOT_VALID_FOR_EVENT:
      "L'état contractuel du PDL nʼest pas autorisé pour la demande de souscription (CFN, PMS, MES)",
    MISSING_URGENT:
      "Il manque la caractéristique urgent (dans le cas dʼune MES et CFN)",
    MISSING_RELEVE_SPECIAL:
      "Il manque la caractéristique releveSpecial (dans le cas dʼune MES et CFN)",
    MISSING_CORRECTION:
      "Il manque la caractéristique releveSpecial (dans le cas dʼune MES et CFN)",
    MISSING_AUTORELEVE:
      "Il manque la caractéristique autoreleve.date (dans le cas dʼune MES)",
    AUTORELEVE_FTA_INCONSISTENCY: "La FTA de lʼauto relève nʼest pas la bonne",
    MISSING_MOTIF_CORRECTION_CODE:
      "Il manque la caractéristique motifCorrectionCode dans le cas où correction est true",
    WRONG_ENEDIS:
      "La caractéristique nʼest pas cohérente avec la valeur renvoyée par Enedis (avec lʼappel du webservice consultationPoint)",
    NON_COMMUNICATING_METER:
      "La caractéristique niveauOuvertureServices est inférieure à 1",
    UNAUTHORIZED_IBAN_COUNTRY_CODE:
      "Le pays de lʼIBAN nʼest pas la liste autorisée par le backend",
    WRONG_IBAN_LENGTH:
      "La longueur de lʼIBAN nʼest pas la bonne (par rapport au pays)",
    INVALID_IBAN_KEY: "La clé de contrôle de lʼIBAN nʼest pas validée (mod 97)",
    MISSING_INDIVIDUAL_PRODUCT: "Il manque le Product INDIVIDUAL",
    BAD_INDIVIDUAL_STATUS: "Le Product INDIVIDUAL nʼa pas le bon statut",
    MISSING_ADVANCE_PRODUCT: "Il manque le Product SOLVABILITE_ACOMPTE",
    ADVANCE_PRODUCT_PRICE_NOT_FOUND:
      "Le prix du Product SOLVABILITE_ACOMPTE nʼa pas été trouvé à date donnée",
    NEXT_RENEWAL_OUT_RANGE:
      "La date de nextRenewalDate choisie pour le produit ELECTRICTE_MENSUALITE est incorrecte",
  },
};

export default fr;
