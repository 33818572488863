import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage";
import autoMergeLevel1 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1";
import paramsStore from "./paramsStore";
import authStore from "./authStore";
import saleStore from "./saleStore";
import adduserStore from "./adduserStore";
import addSocieteStore from "./addSocieteStore";

const persistConfig = {
  key: "root",
  storage: storage,
  stateReconciler: autoMergeLevel1,
  timeout: 500,
};

const reducers = combineReducers({
  params: paramsStore,
  auth: authStore,
  sale: saleStore,
  adduser: adduserStore,
  addsociete: addSocieteStore,
});

const _persistedReducer = persistReducer(persistConfig, reducers);

export const stores = configureStore({
  reducer: _persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        /* ignore persistance actions */
        ignoredActions: [FLUSH, PAUSE, REHYDRATE, PERSIST, REGISTER],
        // ignoredActions: [FLUSH, PAUSE, REHYDRATE, PERSIST, PURGE, REGISTER],
      },
    }),
});
