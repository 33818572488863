import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { customRequest } from "../services/httpRequest/axios";

const checkMaintenance = createAsyncThunk(
   'params/checkMaintenance',
   async (payload, thunkAPI) => {
      const response = await customRequest.get("/actuator/maintenance")
      return response.data
   }
)

const setMaintenance = createAsyncThunk(
   'params/setMaintenance',
   async (status, thunkAPI) => {
      const response = await customRequest.post("/actuator/maintenance/" + status)
      return response.data
   }
)


const paramsSlice = createSlice({
   name: "params",
   initialState: {
      notif: { target: "", action: "", res: "", show: false },
      menuDesktopIsOpen: true,
      authorizations: [
         "ADMIN_PRIMEO",
         "ADMIN_COURTAGE",
         "RESPONSABLE_VENDEUR",
         "VENDEUR",
      ],
      offers: [],
      dates: {
         debit: [
            { key: 0, text: "1", nth: "er" },
            { key: 1, text: "5", nth: "" },
            { key: 2, text: "10", nth: "" },
            { key: 3, text: "15", nth: "" },
         ],
         birth: {
            min: 75,
            max: 18,
            defaultvalue: "1970/06/15",
         },
         start: {
            earlier: 10,
            standard: 15,
         },
         delay: 14,
      },
      links: {
         cgv: "https://www.primeo-energie.fr/conditions-generales-de-vente/",
      },
      creditor:
         "Primeo Energie France – 8 place Boulnois 75017 Paris – ICS FR 02ZZZ80A2FC",
   },
   reducers: {
      update: (state, action) => {
         state[action.payload.target] = action.payload.value;
      },
      empty: (state) => {
         return {
            notif: { target: "", action: "", res: "", show: false },
            menuDesktopIsOpen: true,
            authorizations: [
               "ADMIN_PRIMEO",
               "ADMIN_COURTAGE",
               "RESPONSABLE_VENDEUR",
               "VENDEUR",
            ],
            offers: [],
            dates: {
               debit: [
                  { key: 0, text: "1", nth: "er" },
                  { key: 1, text: "5", nth: "" },
                  { key: 2, text: "10", nth: "" },
                  { key: 3, text: "15", nth: "" },
               ],
               birth: {
                  min: 75,
                  max: 18,
                  defaultvalue: "1970/06/15",
               },
               start: {
                  earlier: 10, // j+7 jours => renonce à son droit de rétractation .
                  standard: 15, // j+1+14 jours
               },
               delay: 14, // +1+14 jours
            },
            links: {
               cgv: "https://www.primeo-energie.fr/conditions-generales-de-vente/",
            },
            creditor:
               "Primeo Energie France – 8 place Boulnois 75017 Paris – ICS FR 02ZZZ80A2FC",
            maintenanceStatus: state.maintenanceStatus
         };
      },
   },
   extraReducers: (builder) => {
      builder.addCase(checkMaintenance.fulfilled, (state, action) => {
         if ((state.maintenanceStatus !== action.payload.status)) {
            console.log(state.maintenanceStatus)
            state.maintenanceStatus = action.payload.status
         }
      })
   }
});

export const { update, empty } = paramsSlice.actions;
export { checkMaintenance, setMaintenance }

export default paramsSlice.reducer;
